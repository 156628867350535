import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/Layout/Layout";
import PageDetails from "../components/PageDetails/PageDetails";
import RichText from "../components/RichText/RichText";

import {
  myWritingsSection,
  excerptGrid,
  excerptCard,
  credits,
  excerpt,
} from "./my-writings.module.scss";

const MyWritings = ({ data }) => {
  const { heading, description, excerptCards } = data.contentfulOtherWorkPage;

  return (
    <Layout>
      <PageDetails heading={heading} description={description} />
      <section className={`grid-wrapper ${myWritingsSection}`}>
        <ul className={excerptGrid}>
          {excerptCards.map((card) => (
            <li className={excerptCard} key={card.id}>
              <div className={credits}>
                <h2>{card.title}</h2>
                <p>{card.platform}</p>
              </div>
              <RichText richText={card.excerpt} className={excerpt} />
              <a
                className="button"
                href={card.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Read more
              </a>
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  );
};

export default MyWritings;

export const query = graphql`
  query {
    contentfulOtherWorkPage {
      heading
      description {
        raw
      }
      excerptCards {
        id
        title
        platform
        link
        excerpt {
          raw
        }
      }
    }
  }
`;

export { Head } from "../components/Head/Head";

